import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { Link } from "gatsby";

// material imports
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
  Chip,
  Stack,
  Container
} from "@mui/material";

// text ellipsis
import LinesEllipsis from "react-lines-ellipsis";

const News = ({ data }) => {
  var postsList = data.allPrismicNews.edges;
  return (
    <Layout>
      <Seo title="News" />
      <div
        className="news-page"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Container maxWidth="xl" sx={{mb: 4}}>
          <Grid
            container
            spacing={4}
            sx={{
              mt: -1,
              mb: 8,
              px: {
                xs: 2,
                sm: 2,
                md: 0,
              },
              pb: 6,
            }}
            noGutters
          >
            <Grid item xs={12}>
              <Link to={postsList[0].node.uid}>
                {/* Desktop version */}
                <Card
                  elevation={15}
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "flex",
                    },
                  }}
                >
                  <CardActionArea>
                    <Grid container spacing={4} noGutters>
                      <Grid item xs={8} sx={{ height: "550px" }}>
                        <img
                          className="main-list-image"
                          src={postsList[0].node.data.image.url}
                          alt={postsList[0].node.data.image.alt}
                          style={{ minHeight: "100%" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{
                          pr: 4,
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          height: "550px",
                        }}
                      >
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            sx={{ fontWeight: 800, fontSize: "14px" }}
                          >
                            {postsList[0].node.data.publish_date}
                          </Typography>
                          <Chip
                            label="Featured"
                            sx={{ backgroundColor: "rgb(255,190,0)" }}
                            size="small"
                          />
                        </Stack>

                        <Typography
                          component="h2"
                          sx={{
                            color: "#000dff",
                            fontSize: "2rem",
                            lineHeight: "1.25",
                            fontWeight: 500,
                            mb: 2,
                            mt: 1,
                          }}
                        >
                          <LinesEllipsis
                            text={postsList[0].node.data.title.text}
                            maxLine="4"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                          />
                        </Typography>
                        <Typography
                          sx={{
                            color: "#364151",
                            fontSize: "18px",
                            fontWeight: 400,
                          }}
                        >
                          <LinesEllipsis
                            text={postsList[0].node.data.seo_description.text}
                            maxLine="4"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardActionArea>
                </Card>

                {/* Mobile version */}
                <Card
                  elevation={15}
                  sx={{
                    display: {
                      xs: "flex",
                      sm: "flex",
                      md: "none",
                    },
                  }}
                >
                  <CardActionArea>
                    <CardMedia>
                      <img
                        className="main-list-image"
                        src={postsList[0].node.data.image.url}
                        alt={postsList[0].node.data.image.alt}
                      />
                    </CardMedia>
                    <CardContent
                      sx={{
                        p: 4,
                        mb: 4,
                        height: {
                          xs: "auto",
                          md: "350px",
                        },
                      }}
                    >
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography sx={{ fontWeight: 800, fontSize: "14px" }}>
                          {postsList[0].node.data.publish_date}
                        </Typography>
                        <Chip
                          label="Featured"
                          sx={{ backgroundColor: "rgb(255,190,0)" }}
                          size="small"
                        />
                      </Stack>
                      <Typography
                        component="h2"
                        sx={{
                          color: "#000dff",
                          fontSize: "2rem",
                          lineHeight: "1.25",
                          fontWeight: 500,
                          mb: 2,
                          mt: 1,
                        }}
                      >
                        <LinesEllipsis
                          text={postsList[0].node.data.title.text}
                          maxLine="4"
                          ellipsis="..."
                          trimRight
                          basedOn="letters"
                        />
                      </Typography>
                      <Typography
                        sx={{
                          color: "##364151",
                          fontSize: "18px",
                          fontWeight: 400,
                        }}
                      >
                        <LinesEllipsis
                          text={postsList[0].node.data.seo_description.text}
                          maxLine="4"
                          ellipsis="..."
                          trimRight
                          basedOn="letters"
                        />
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
            {postsList
              .filter((element, index) => index > 0)
              .map(({ node, i }) => (
                <Grid item xs={12} md={4}>
                  <Link to={node.uid} key={i}>
                    <Card elevation={15}>
                      <CardActionArea>
                        <CardMedia>
                          <img
                            className="main-list-image"
                            src={node.data.image.url}
                            alt={node.data.image.alt}
                          />
                        </CardMedia>
                        <CardContent
                          sx={{
                            p: 4,
                            mb: 4,
                            height: {
                              xs: "auto",
                              md: "350px",
                            },
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: 800, fontSize: "14px" }}
                          >
                            {node.data.publish_date}
                          </Typography>
                          <Typography
                            component="h2"
                            sx={{
                              color: "#000dff",
                              fontSize: "2rem",
                              lineHeight: "1.25",
                              fontWeight: 500,
                              mb: 2,
                              mt: 1,
                            }}
                          >
                            <LinesEllipsis
                              text={node.data.title.text}
                              maxLine="4"
                              ellipsis="..."
                              trimRight
                              basedOn="letters"
                            />
                          </Typography>
                          <Typography
                            sx={{
                              color: "##364151",
                              fontSize: "18px",
                              fontWeight: 400,
                            }}
                          >
                            <LinesEllipsis
                              text={node.data.seo_description.text}
                              maxLine="4"
                              ellipsis="..."
                              trimRight
                              basedOn="letters"
                            />
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Link>
                </Grid>
              ))}
          </Grid>
        </Container>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query MyQuery {
    allPrismicNews(sort: { fields: data___publish_date, order: DESC }) {
      edges {
        node {
          id
          uid
          data {
            image {
              url
              alt
            }
            title {
              text
            }
            publish_date(formatString: "D MMMM YYYY")
            seo_title {
              text
            }
            seo_description {
              text
            }
            content_field {
              raw
            }
          }
        }
      }
    }
  }
`;

export default News;
